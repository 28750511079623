<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text"><b>Statistik Desa</b></div>
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <h4 class="font-weight-bold">Statistik Penduduk</h4>
      </div>
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2">Usia Penduduk</span>
            <AgeChart></AgeChart>
          </template>
        </Card>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2">Jenis Kelamin</span>
            <GenderChart></GenderChart>
          </template>
        </Card>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2">Pendidikan</span>
            <EducationChart></EducationChart>
          </template>
        </Card>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2">Agama</span>
            <ReligionChart></ReligionChart>
          </template>
        </Card>
      </div>
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2">Pekerjaan</span>
            <ProfessionChart></ProfessionChart>
          </template>
        </Card>
      </div>
      <div class="col-md-12">
        <h4 class="font-weight-bold">Statistik Sarana & Prasarana</h4>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2"
              >Sarana & Prasarana Desa</span
            >
            <InfrastructureChart></InfrastructureChart>
          </template>
        </Card>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2">Rumah Ibadah</span>
            <PrayPlaceChart></PrayPlaceChart>
          </template>
        </Card>
      </div>
      <div class="col-md-12">
        <h4 class="font-weight-bold">Statistik Usaha Desa</h4>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2"
              >Komoditas Usaha Desa</span
            >
            <CommodityChart></CommodityChart>
          </template>
        </Card>
      </div>
      <div class="col-md-6">
        <Card>
          <template v-slot:body>
            <span class="d-block font-weight-bold my-2"
              >Status Kepemilikan Tempat Usaha</span
            >
            <StatusChart></StatusChart>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AgeChart from "@/view/components/statistic/AgeChart.vue";
import GenderChart from "@/view/components/statistic/GenderChart.vue";
import EducationChart from "@/view/components/statistic/EducationChart.vue";
import InfrastructureChart from "@/view/components/statistic/InfrastructureChart.vue";
import PrayPlaceChart from "@/view/components/statistic/PrayPlaceChart.vue";
import ReligionChart from "@/view/components/statistic/ReligionChart.vue";
import ProfessionChart from "@/view/components/statistic/ProfessionChart.vue";
import CommodityChart from "@/view/components/statistic/CommodityChart.vue";
import StatusChart from "@/view/components/statistic/StatusChart.vue";
import module from "@/core/modules/CrudModule.js";

export default {
  name: "statistic",
  data() {
    return {
      dataStatistic: {},
    };
  },
  components: {
    Card,
    AgeChart,
    GenderChart,
    EducationChart,
    ReligionChart,
    InfrastructureChart,
    PrayPlaceChart,
    ProfessionChart,
    CommodityChart,
    StatusChart
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Statistik", route: "" }]);
  },
};
</script>
